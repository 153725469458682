@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

$className: faq-embed;
$classNameCard: faq-card;

.#{$className} {
  margin: 48px 0;
  width: 100%;

  [id^='faq-embed-'] {
    &:target {
      padding-top: 100px; /* height of your fixed header or any additional space you want */
    }
  }

  &__headline {
    color: var(--article-faqembed-headline-font-color);
    font-family: var(--article-faqembed-headline-font-family);
    font-weight: var(--article-faqembed-headline-font-weight);
    font-size: var(--article-faqembed-headline-font-size);
    line-height: var(--article-faqembed-headline-line-height);
    margin: 0 0 24px;
    position: relative;

    span {
      background: var(--white);
      padding-right: 18px;
      position: relative;
      z-index: 2;
    }

    &::after {
      bottom: var(--article-faqembed-headline-accent-line-bottom);
      border-top: 1px solid var(--article-faqembed-headline-font-color);
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &__cards {

    .#{$classNameCard}__card {
      border-bottom: var(--article-faqembed-card-border-bottom);
      font-family: var(--article-faqembed-card-font-family);
      margin-bottom: var(--article-faqembed-card-margin-bottom);
      padding-bottom: var(--article-faqembed-card-padding-bottom);

      a {
        color: var(--article-faqembed-card-link-color);
        border-bottom: var(--article-faqembed-card-link-underline-width) solid var(--article-faqembed-card-link-underline-color);
        padding-bottom: var(--article-faqembed-card-link-underline-padding);
      }

      a:hover {
        color: var(--article-faqembed-card-link-hover-color);
        opacity: var(--article-faqembed-card-link-hover-opacity);
      }

      a:active {
        opacity: var(--article-faqembed-card-link-active-opacity);
      }

      ul > li {
        padding: 1.5rem 0 0;
        margin-left: 18px;
        color: var(--article-faqembed-card-font-color);
        padding-left: var(--article-faqembed-list-item-padding-left);

        &::marker {
          color: var(--article-faqembed-bullet-color);
        }

        @include h.breakpoint-m {
          margin-left: 20px;
        }

        @include h.breakpoint-xx {
          margin-left: 25px;
        }
      }

      li {
        list-style-type: disc;
      }

      ol > li {
        margin: 0 0 1.5rem !important;
      }

      p {
        margin: var(--article-faqembed-card-answer-margin);
      }

      .#{$classNameCard}__content {
        &.faq--active {
          svg {
            transform: rotate(180deg);
          }
          .#{$classNameCard}__question {
            margin-bottom: var(--article-faqembed-card-question-margin-bottom-active);
          }
        }
      }

      .#{$classNameCard}__question {
        align-items: baseline;
        background: transparent;
        border: 0;
        color: var(--article-faqembed-card-question-font-color);
        cursor: pointer;
        display: flex;
        font-size: var(--article-faqembed-card-question-font-size);
        font-family: var(--article-faqembed-card-question-font-family);
        font-weight: var(--article-faqembed-card-question-font-weight);
        line-height: var(--article-faqembed-card-question-line-height);
        justify-content: space-between;
        padding: 0;
        text-align: left;
        width: 100%;

        svg {
          height: 12.14px;
          margin-left: 1rem;
          min-width: 21px;
          transition: 0.2s all;
          width: 21px;

          path {
            stroke-width: 1;
          }
        }
      }

      .#{$classNameCard}__answer {
        font-family: var(--article-faqembed-card-answer-font-family);
        font-weight: var(--article-faqembed-card-answer-font-weight);
        font-size: var(--article-faqembed-card-answer-font-size);
        line-height: var(--article-faqembed-card-answer-line-height);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }
    }
  }
}

body.today {
  .#{$classNameCard}__card {
    ul > li {
      &::marker {
        color: transparent;
      }

      &::before {
        color: var(--article-body-bullet-color);
        background-repeat: no-repeat;
        content: '\2022';
        display: inline-block;
        margin-left: calc(-1 * var(--article-body-bullet-width));
        width: var(--article-body-bullet-width);
        text-align: var(--article-body-bullet-text-align);
        vertical-align: var(--article-body-bullet-vertical-align);
        font-size: var(--article-body-bullet-font-size);
        line-height: 50%;
      }
    }
  }
}
